import { createSlice } from "@reduxjs/toolkit";

const threeSlice = createSlice({
  name: "Narration",
  initialState: {
    lang: 0,
    langs: ["en", "ar", "es"],
  },
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { setLang } = threeSlice.actions;

export default threeSlice.reducer;
