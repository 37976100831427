import { Power2 } from "gsap";
import React from "react";
import { Tween } from "react-gsap";
import { useNavigate } from "react-router-dom";
import useWidth from "../hooks/useWidth";
import { LmLogo } from "../utils/graphics";

const Landing = () => {
  const navigate = useNavigate();
  const w = useWidth();
  return (
    <div className="w-[100%] h-[100vh] relative">
      <img
        src="/imgg.jpg"
        className="w-[100vw] h-[100vh] absolute z-0 object-cover"
        alt=""
      />
      <div className="w-[100%] good-font-6 font-bold  h-[100vh] absolute z-10 ">
        <Tween
          from={{
            opacity: 0,
          }}
          to={{
            opacity: 1,
          }}
          duration={0.75}
          delay={0.5}
          ease={Power2.easeInOut}
        >
          <img
            src="/logo1.png"
            className="mobile:w-[70px] laptop:w-[130px] absolute mobile:top-[10px] mobile:right-[10px] laptop:top-[30px] laptop:right-[30px] object-contain"
            alt=""
          />
          {/* <img
            src="/logo.jpg"
            className=" mobile:w-[60px] laptop:w-[180px] absolute laptop:top-[100px] mobile:top-[30px] mobile:right-[90px] laptop:right-[270px] object-contain"
            alt=""
          /> */}
          <LmLogo
            classes={
              "absolute laptop:top-[100px] bg-[#ffffff20] laptop:scale-100 mobile:scale-50 mobile:top-[15px] mobile:right-[40px] laptop:right-[200px]"
            }
          />
        </Tween>
        <Tween
          from={{
            clipPath: "inset(0px 30vw 0vh 30vw)",
            opacity: 0,
          }}
          to={{
            clipPath: "inset(0px 0vw 0vh 0vw)",
            opacity: 1,
          }}
          duration={0.75}
          delay={2.5}
          ease={Power2.easeInOut}
        >
          <div
            onClick={() => {
              navigate("/presentation");
            }}
            className="text-white laptop:backdrop-blur-0 mobile:backdrop-blur-sm laptop:bg-[#ffffff00] mobile:bg-[#00000010] laptop:no-underline mobile:underline px-[10px] py-[10px] cursor-pointer hover:underline underline-offset-[3px] absolute whitespace-nowrap bottom-[50px] mobile:right-[15px] laptop:right-[50px] mobile:text-[25px] laptop:text-[60px]"
          >
            XIII FIP WORLD
            {w < 1000 && <br />} POLO CHAMPIONSHIP <br />
          </div>
        </Tween>
      </div>
    </div>
  );
};

export default Landing;
