import React, { Suspense, useEffect, useState } from "react";

const HomePage = React.lazy(() => import("../view/home.jsx"));

const Home = () => {
  const [login, setLogin] = useState(false);
  const [input, setInput] = useState("");

  useEffect(() => {
    const lg = localStorage.getItem("key");
    if (lg === "true") {
      setLogin(true);
    }
  });

  return (
    <>
      {true ? (
        <Suspense fallback={null}>
          <HomePage />
        </Suspense>
      ) : (
        <div className="w-[100%] h-[100vh] relative">
          <div className="w-[100%] h-[100vh] bg-[#000] flex-col flex items-center justify-center">
            <div className="flex mobile:w-[280px] laptop:w-[500px] justify-between">
              <img
                src="/logo.jpg"
                className="mobile:w-[130px] laptop:w-[230px] object-contain"
                alt=""
              />
              <div className="w-[3px] h-[100%] bg-white"></div>
              <img
                src="/img394.jpg"
                className="mobile:w-[130px] laptop:w-[230px] object-contain"
                alt=""
              />
            </div>
            <input
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (input === "#LMPOLO74") {
                    localStorage.setItem("key", true);
                  }
                }
              }}
              type="password"
              placeholder="Enter Your Password"
              className="mobile:w-[280px] laptop:w-[500px] bg-transparent good-fonted border-white border-[2px] text-white mobile:h-[38px] laptop:h-[50px] rounded-sm mobile:text-[14px] laptop:text-[18px] outline-none px-[20px] font-medium  mt-[20px] border-[2px]  "
            />
            <div
              onClick={() => {
                if (input === "#LMPOLO74") {
                  localStorage.setItem("key", true);
                  setLogin(true);
                }
              }}
              className="laptop:w-[500px] mobile:w-[280px] cursor-pointer text-black mobile:h-[34px] laptop:h-[50px] bg-white mt-[15px] mobile:text-[14px] laptop:text-[18px] rounded-sm flex items-center justify-center good-fonted font-medium "
            >
              Submit
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
